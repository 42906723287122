import { ClientAuthService } from "@/api/instance";
import { SITE } from "@/config";
import store from "@/store";
import { DateTime } from "luxon";

export const Login = async req => {
  req.site = SITE;
  let {
    data: { token, formCompleted, mfa_token, self, last_seen }
  } = await ClientAuthService.post("/login", req);
  if (mfa_token != null) {
    return mfa_token;
  } else {
    last_seen = DateTime.fromISO(last_seen)
      .setZone("utc")
      .toISO();

    localStorage.setItem("token", token);
    localStorage.setItem("formCompleted", formCompleted || false);
    localStorage.setItem("self", self);
    localStorage.setItem("last_seen", last_seen);

    store.commit("set_token", token);
    store.commit("set_self", self);
    store.commit("set_last_seen", last_seen);
  }
  return null;
};

export const MfaLogin = async (mfa_token, code) => {
  let {
    data: { token, formCompleted = false }
  } = await ClientAuthService.post("/login/mfa", { mfa_token, code });
  localStorage.setItem("token", token);
  localStorage.setItem("formCompleted", formCompleted);
  store.commit("set_token", token);
};

export const FirstTimeEnableMFA = async (token, code) => {
  await ClientAuthService.put("/enable_mfa", { token, code });
};
