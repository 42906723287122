<template>
  <div class="login-container">
    <h1 class="login-title">Login</h1>
    <v-form @submit.prevent="login()">
      <p class="input-label">Email <span>*</span></p>
      <v-text-field
        name="email"
        v-model="user.email"
        type="email"
        outlined
        :rules="[rules.required, rules.email]"
        validate-on-blur
        prepend-inner-icon="mail"
        autocomplete="email"
      ></v-text-field>
      <p class="input-label">Password <span>*</span></p>
      <v-text-field
        name="password"
        v-model="user.password"
        outlined
        type="password"
        :rules="[rules.required]"
        prepend-inner-icon="lock"
        autocomplete="current-password"
      >
      </v-text-field>
      <div class="btn-container">
        <router-link to="forgot-password" class="forgot-password">
          <p>Forgot password</p>
        </router-link>
        <v-btn type="submit" color="primary">
          Login
        </v-btn>
      </div>
    </v-form>

    <v-dialog v-model="show_mfa" width="500">
      <MFATokenEntry
        :mfa_title="mfa_title"
        :mfa_text="mfa_text"
        :init_mfa_title="init_mfa_title"
        :init_mfa_text="init_mfa_text"
        :init_mfa_login="init_mfa_login"
        @codesubmission="mfaLogin"
      ></MFATokenEntry>
    </v-dialog>
    <v-dialog v-model="show_mfa_enable" width="500">
      <MFATokenEntry
        :mfa-title="init_mfa_title"
        :mfa-text="init_mfa_text"
        @codesubmission="mfaEnable"
      ></MFATokenEntry>
    </v-dialog>
  </div>
</template>

<script>
import { Login, MfaLogin, FirstTimeEnableMFA } from "@/api/auth/login";
import MFATokenEntry from "@/components/mfa-token-entry.vue";
import EventBus from "@/event-bus";
export default {
  components: { MFATokenEntry },
  data() {
    return {
      mfa_enable_token: this.$route.query.mfa_enable_token,
      show_mfa_enable: this.$route.query?.mfa_enable_token?.length > 0,
      show_mfa: false,
      init_mfa_title: "Enabling Two Factor Authentication",
      init_mfa_text:
        "To confirm the use of Two Factor Authentication on this account please enter your code below. Once confirmed you will be required to perform authentication upon each login.",
      mfa_title: "",
      mfa_text: "",
      mfa_token: null,
      user: {
        email: "",
        password: "",
        userType: "CLIENT"
      },
      rules: {
        required: value => !!value || "Required.",
        email: value => {
          const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(value) || "Invalid email";
        }
      }
    };
  },
  methods: {
    async login() {
      this.user.email = this.user.email.toLowerCase();
      try {
        let mfa_token = await Login(this.user);
        if (mfa_token != null) {
          this.mfa_token = mfa_token;
          this.show_mfa = true;
          return;
        }
        EventBus.$emit("toast", {
          text: "Logged in Successfully",
          type: "success"
        });
      } catch (e) {
        EventBus.$emit("toast", {
          text: "Error Logging in",
          type: "error"
        });
        throw e;
      }
      let formCompleted = localStorage.getItem("formCompleted");
      if (formCompleted === "false") {
        this.$router.push("/home/requests");
      } else {
        this.$router.push("/home/form");
      }
    },
    async mfaLogin(code) {
      try {
        await MfaLogin(this.mfa_token, code);
        EventBus.$emit("toast", {
          text: "Logged in Successfully",
          type: "success"
        });
      } catch (e) {
        EventBus.$emit("toast", {
          text: "Error Logging in",
          type: "error"
        });
        throw e;
      }
      let formCompleted = localStorage.getItem("formCompleted");
      if (formCompleted === "false") {
        this.$router.push("/home/form");
      } else {
        this.$router.push("/home/requests");
      }
    },
    async mfaEnable(code) {
      await FirstTimeEnableMFA(this.mfa_enable_token, code);
      this.show_mfa_enable = false;
    }
  }
};
</script>

<style scoped>
.login-container {
  max-width: 650px;
  width: 95%;
  margin: 0 auto;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.login-title {
  font-size: 2rem;
  font-weight: 400;
  color: var(--v-grey-base);
  text-align: center;
  margin-bottom: 60px;
}
.forgot-password {
  color: inherit;
  align-self: center;
  font-size: 20px;
}
.forgot-password p {
  margin: 0;
}
.input-label {
  font-size: 22px;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}

@media only screen and (max-width: 767px) {
  .btn-container {
    margin-top: 0;
  }
  .login-title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .input-label {
    font-size: 18px;
  }
  .forgot-password p {
    font-size: 18px;
  }
  ::v-deep .v-btn__content {
    font-size: 18px;
  }
}
</style>
